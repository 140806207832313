import { mlmApi } from 'apis/mlm.api';
import { stripeApi, useCreateAccountMutation, useCreateCheckoutSessionMutation, useMakePayoutMutation } from 'apis/stripe.api';
import { useDispatch } from 'react-redux';
export const useStripeService = () => {
  const [createCheckoutSessionMutation, {isLoading : isCreateCheckoutSessionMutationLoading}] = useCreateCheckoutSessionMutation();
  const [createAccountMutation, {isLoading : isCreateAccountMutationLoading}] = useCreateAccountMutation();
  const [makePayoutMutation, {isLoading : isMakePayoutMutationLoading}] = useMakePayoutMutation();
  const dispatch = useDispatch();
  const createCheckoutSession = async (body) => {
    try {
      const result = await createCheckoutSessionMutation(body).unwrap();
      dispatch(mlmApi.util.resetApiState());
      dispatch(stripeApi.util.resetApiState());
      return result;
    } catch (error) {
      throw error;
    }
  };
  
  const createAccount = async (body) => {
    try {
      const result = await createAccountMutation(body).unwrap();
      return result;
    } catch (error) {
      throw error;
    }
  };
  const makePayout = async (body) => {
    try {
      const result = await makePayoutMutation(body).unwrap();
      dispatch(stripeApi.util.resetApiState());
      return result;
    } catch (error) {
      throw error;
    }
  };
  return { createCheckoutSession,createAccount, makePayout, isCreateCheckoutSessionMutationLoading, isCreateAccountMutationLoading, isMakePayoutMutationLoading};
};