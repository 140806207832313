import { createSlice } from '@reduxjs/toolkit'

const initialState = { agentStatus : null } 

const mlmSlice = createSlice({
  name: 'mlm',
  initialState,
  reducers: {
    setAgentStatus(state,  action) {
      state.agentStatus = action.payload;
    },
    unsetAgentStatus(state) {
      state.agentStatus = null;
    },
  },
})

export const { setAgentStatus, unsetAgentStatus} = mlmSlice.actions
export default mlmSlice.reducer