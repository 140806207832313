import workspace from './agent/workspace';
import me from './agent/me';
import admin_dashboard from './admin/admin_dashboard';


// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [workspace, me, admin_dashboard]
};

export default menuItems;
