import { useActivateAgentMutation, useCreatePromoCodeMutation, useDeactivateAgentMutation, useDeletePromoCodeMutation } from 'apis/admin.api';
import { stripeApi } from 'apis/stripe.api';
import { is } from 'immutable';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
export const useAdminService = () => {
  const navigate = useNavigate();
  const [activateAgentMutation, {isLoading : isActivateAgentMutationLoading}] = useActivateAgentMutation();
  const [deactivateAgentMutation, {isLoading : isDeactivateAgentMutationLoading}] = useDeactivateAgentMutation();
  const [deletePromoCodeMutation, {isLoading : isDeletePromoCodeLoading}] = useDeletePromoCodeMutation();
  const [createPromoCodeMutation, {isLoading : isCreatePromoCodeLoading}] = useCreatePromoCodeMutation();




  const dispatch = useDispatch();
  const activateAgent = async (datas) => {
    try {
      const result = await activateAgentMutation(datas).unwrap();
      // toast(`L'agent a été activé avec succès.`);
      return result;
    } catch (error) {
      throw error;
    }
  };

  const deactivateAgent = async (datas) => {
    try {
      const result = await deactivateAgentMutation(datas).unwrap();
      // toast(`L'agent a été desactivé avec succès.`);
      return result;
    } catch (error) {
      throw error;
    }
  };

  const deletePromoCode = async (datas) => {
    try {
      const result = await deletePromoCodeMutation(datas).unwrap();
      // toast(`L'agent a été desactivé avec succès.`);
      return result;
    } catch (error) {
      throw error;
    }
  };
  const createPromoCode = async (datas) => {
    try {
      const result = await createPromoCodeMutation(datas).unwrap();
      // toast(`L'agent a été desactivé avec succès.`);
      return result;
    } catch (error) {
      throw error;
    }
  };

  
  return { activateAgent, deactivateAgent , isActivateAgentMutationLoading, isDeactivateAgentMutationLoading, deletePromoCode, isDeletePromoCodeLoading, createPromoCode, isCreatePromoCodeLoading };
};