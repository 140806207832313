import { configureStore } from '@reduxjs/toolkit'

import logger from 'redux-logger'
import rootReducer from './reducer'
import { authApi } from 'apis/auth.api'; // Assuming you have an auth API slice
import { persistStore } from 'redux-persist';
import { mlmApi } from 'apis/mlm.api';

import { isRejectedWithValue } from '@reduxjs/toolkit'
import { setUserToken } from 'slices/auth.slice';
import { stripeApi } from 'apis/stripe.api';
import { adminApi } from 'apis/admin.api';
/**
 * Logout the user if 401 error
 */
export const unauthorizedMiddleware =
  (store) => (next) => (action) => {
    if (isRejectedWithValue(action) && action.payload.status == 401) {
      // let state = store.getState();
      store.dispatch(mlmApi.util.resetApiState());
      store.dispatch(stripeApi.util.resetApiState());
      store.dispatch(adminApi.util.resetApiState());

      store.dispatch(setUserToken(null));
    }
    return next(action)
  }

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  }).concat(
    authApi.middleware, 
    mlmApi.middleware, 
    stripeApi.middleware,
    adminApi.middleware,

    unauthorizedMiddleware

    // logger
  ),
  devTools: process.env.NODE_ENV !== 'production',
})
export const persistor = persistStore(store);