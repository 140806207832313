// material-ui
import { Link, Typography, Stack } from '@mui/material';

// ==============================|| FOOTER - AUTHENTICATION 2 & 3 ||============================== //

const AuthFooter = () => (
  <Stack direction="row" justifyContent="space-between">
    <Typography variant="subtitle2">
      2024
    </Typography>
    <Typography variant="subtitle2" component={Link} href="https://greenlifeultimate.fr/" target="_blank" underline="hover">
      Little Ponails
    </Typography>
  </Stack>
);

export default AuthFooter;
