import { useLoginMutation, useRegisterMutation } from 'apis/auth.api';
import { useDispatch } from 'react-redux';
import { setUserRoles, setUserToken } from 'slices/auth.slice';
import { useNavigate } from 'react-router-dom';
import { mlmApi, useGetAgentInfosQuery, useLazyGetAgentInfosQuery } from 'apis/mlm.api';
import { stripeApi } from 'apis/stripe.api';
import { setAgentStatus } from 'slices/mlm.slice';
export const useAuthService = () => {
  const navigate = useNavigate();
  const [loginMutation] = useLoginMutation();
  const [registerMutation] = useRegisterMutation();
  const [trigger] = useLazyGetAgentInfosQuery();
  const dispatch = useDispatch();

  const logout = async () => {
    try {
      dispatch(mlmApi.util.resetApiState())
      dispatch(stripeApi.util.resetApiState())
      dispatch(setUserRoles([]));
      dispatch(setUserToken(null));

      navigate('/auth');
    } catch (error) {
      throw error;
    }
  };

  const login = async (credentials) => {
    try {
      const result = await loginMutation(credentials).unwrap();
      

      await dispatch(setUserToken(result.token));
      await dispatch(setUserRoles(result.roles));
      
      if (result.roles.includes("ROLE_ADMIN")) {
        navigate('/admin');
      }
      else if (result.roles.includes("ROLE_AGENT")){
        let agentInfos = await trigger().unwrap()
        console.log("agentInfos after login", agentInfos)
        await dispatch(setAgentStatus(agentInfos?.agent?.status));
        navigate('/agent');
      }
      
      
      return result;
    } catch (error) {
      throw error;
    }
  };

  const register = async (userData) => {
    try {
      const result = await registerMutation(userData).unwrap();
      await dispatch(setUserToken(result.token));
      await dispatch(setUserRoles(result.roles));

      if (result.roles.includes("ROLE_ADMIN")) {
        navigate('/admin');
      }
      else if (result.roles.includes("ROLE_AGENT")){
        let agentInfos = await trigger().unwrap()
        await dispatch(setAgentStatus(agentInfos?.agent?.status));
        navigate('/agent');
      }

      return result;
    } catch (error) {
      throw error;
    }
  };

  return { login, register, logout};
};