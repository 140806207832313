
// project imports
import { Link, useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Button, Divider, Grid, Stack, Typography, useMediaQuery } from '@mui/material';

// project imports
import Logo from 'ui-component/Logo';
import AuthFooter from 'ui-component/cards/AuthFooter';
import AuthCardWrapper from 'views/pages/authentication/AuthCardWrapper';
import MainCard from 'ui-component/cards/MainCard';
import { useEffect, useState } from 'react';
import { useGetAgentInfosQuery } from 'apis/mlm.api';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useStripeService } from 'services/stripe.service';
import { useSelector } from 'react-redux';

// ==============================|| SAMPLE PAGE ||============================== //

const AgentKitPurchase = () => {
  const agentStatus = useSelector((state) => state.mlm.agentStatus);
  console.log("agentStatus", agentStatus);

  const navigate = useNavigate();
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const { data: agentInfos, error: getAgentInfosError, isLoading: getAgentInfosIsLoading } = useGetAgentInfosQuery();

  const { createCheckoutSession } = useStripeService();
  const [errorMessage, setErrorMessage] = useState(null);
  const handleCreateCheckoutSession = async () => {
    try {
      console.log(window.location.origin);
      const res = await createCheckoutSession({
        "items": [
          {
            "product_id": 1, // GREENLIFE SUBSCRIPTION
            "quantity": 1
          }
        ],
        "success_url": `${window.location.origin}/agent/workspace/dashboard`,
        "cancel_url": `${window.location.origin}/agent/me/agent-registration`,
        "mode": "subscription",
        "metadata": {
          "is_agent_subscription": true,
          "description": "Abonnement à Little Ponails"
        }
      });
      console.log(res);
      window.location.replace(res["url"]);
    }
    catch (err) {
      console.log(err);
      setErrorMessage(err.data ? err.data.message : err.message);
    }
  }
  return (
    <MainCard
      sx={{

        '& > *': {
          flexGrow: 1,
          flexBasis: '50%'
        }
      }}
      content={false}
    >
      <Box sx={{ p: 3 }}>

        <h1 style={{lineHeight:"35px"}}>Espace Little Egérie Little Po'nails</h1>
        <h3>Bienvenue sur notre page d’inscription pour devenir Little Egérie.</h3>
        <p>En rejoignant notre programme, vous aurez l’opportunité de développer votre propre activité tout en représentant une marque qui vous passionne.</p>

        <p>Pour commencer cette aventure, vous devrez acheter un de nos kits de démarrage, essentiel pour bien débuter.</p>
        <p>Le kit contient tous les outils et produits nécessaires pour vous lancer et faire découvrir la gamme à vos futurs clients. L’achat de ce kit est requis pour finaliser votre inscription et garantir que vous disposez des ressources adéquates pour réussir dès le premier jour.</p>

        <p>N’attendez plus, achetez votre Kit <a href={process.env.REACT_APP_LITTLE_PONAILS_STARTER_KIT_SHOP_URL} target="_blank" >ici</a> et préparez-vous à faire décoller votre activité avec nous !</p>

        <p>Après votre inscription, nous vous invitons à remplir ce document ‘<a href={process.env.REACT_APP_LITTLE_PONAILS_FORM_LINK} target="_blank" >ici</a>’ pour formaliser notre collaboration et nous le renvoyer à l’adresse email contact@littleponails.fr</p>
        <div>
          <Button variant="contained" color="secondary" sx={{ boxShadow: 'none' }} style={{ textTransform: 'none' }}
            onClick={async () => {
              window.open(`${process.env.REACT_APP_LITTLE_PONAILS_STARTER_KIT_SHOP_URL}`, "_blank")
            }}>
            Acheter mon kit de démarrage
          </Button>
        </div>
        <div style={{marginTop: 10}}>
          <Button variant="contained" color="primary" sx={{ boxShadow: 'none' }} style={{ textTransform: 'none' }}
            onClick={async () => {
              navigate("/agent/contract");
            }}>
            Remplir le contrat de revendeur
          </Button>
        </div>

      </Box>
    </MainCard >
  );
}
export default AgentKitPurchase;
