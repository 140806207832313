
// project imports
import { Link, useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Button, Divider, Grid, Stack, Typography, useMediaQuery } from '@mui/material';

// project imports
import Logo from 'ui-component/Logo';
import AuthFooter from 'ui-component/cards/AuthFooter';
import AuthCardWrapper from 'views/pages/authentication/AuthCardWrapper';
import MainCard from 'ui-component/cards/MainCard';
import { useEffect, useState } from 'react';
import { useGetAgentInfosQuery } from 'apis/mlm.api';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useStripeService } from 'services/stripe.service';
import { useSelector } from 'react-redux';

// ==============================|| SAMPLE PAGE ||============================== //

const AgentAccountDeactivated = () => {
  const agentStatus = useSelector((state) => state.mlm.agentStatus);
  console.log("agentStatus", agentStatus);

  const navigate = useNavigate();
  const theme = useTheme();
    return (
    <MainCard
      sx={{

        '& > *': {
          flexGrow: 1,
          flexBasis: '50%'
        }
      }}
      content={false}
    >
      <Box sx={{ p: 3 }}>

        <h1 style={{lineHeight:"35px"}}>Espace Little Egérie Little Po'nails</h1>
        <p>Votre compte a été désactivé, veuillez contacter l'administrateur du site si besoin.</p>
        <div>
          <Button variant="contained" color="secondary" sx={{ boxShadow: 'none' }} style={{ textTransform: 'none' }}
            onClick={async () => {
              window.open(`${process.env.REACT_APP_LITTLE_PONAILS_SHOP_URL}`, "_blank")
            }}>
            Visiter la boutique
          </Button>
        </div>
      </Box>
    </MainCard >
  );
}
export default AgentAccountDeactivated;
