import { createSlice } from '@reduxjs/toolkit'

const initialState = { token: null, roles: [] } 

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserToken(state,  action) {
      state.token = action.payload;
      console.log("New token", state.token)
    },
    setUserRoles(state,  action) {
      state.roles = action.payload;
      console.log("USER ROLES", state.roles)
    },
    unsetUserToken(state) {
      state.token = null;
    },
    unsetUserRoles(state) {
      state.roles = [];
    },
  },
})

export const { setUserToken, unsetUserToken, setUserRoles, unsetUserRoles} = authSlice.actions
export default authSlice.reducer