// assets
import { IconDashboard, IconBasket, IconNetwork , IconWorld, IconUsersGroup, IconGiftCard} from '@tabler/icons-react';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const admin_dashboard = {
  id: 'admin-dashboard',
  title: 'Tableau de bord',
  type: 'group',
  roles: ['ROLE_ADMIN'],
  children: [
    {
      id: 'agents-list',
      title: 'Liste des agents',
      type: 'item',
      url: '/admin/agents',
      icon: IconUsersGroup,
      breadcrumbs: false
    },
    {
      id: 'promo-codes-list',
      title: 'Liste des codes promo',
      type: 'item',
      url: '/admin/promo_codes',
      icon: IconGiftCard,
      breadcrumbs: false
    },
  ]
};

export default admin_dashboard;
