
// project imports
import { Link, useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
// project imports
import Logo from 'ui-component/Logo';
import AuthFooter from 'ui-component/cards/AuthFooter';
import AuthCardWrapper from 'views/pages/authentication/AuthCardWrapper';
import MainCard from 'ui-component/cards/MainCard';
import { useEffect, useRef, useState } from 'react';
import { useGetAgentDetailsQuery, useGetAgentInfosQuery } from 'apis/mlm.api';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useStripeService } from 'services/stripe.service';
import { useSelector } from 'react-redux';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
  useMediaQuery
} from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';
import useScriptRef from 'hooks/useScriptRef';
import { useMlmService } from 'services/mlm.service';
import { LoadingButton } from '@mui/lab';
import ReactSignatureCanvas from 'react-signature-canvas';
import { borderColor, borderRadius } from '@mui/system';
// ==============================|| SAMPLE PAGE ||============================== //

const AgentContractForm = () => {
  const agentStatus = useSelector((state) => state.mlm.agentStatus);
  console.log("agentStatus", agentStatus);
  const { data: agent } = useGetAgentDetailsQuery();
  const ref = useRef(null);


  useEffect(() => {
    if (agent == null) return;
    if(!agent.agentContracts || agent.agentContracts.length === 0) return;
    let lastContract = agent.agentContracts[agent.agentContracts.length - 1];
    ref.current.setFieldValue("lastname", lastContract["lastname"]);
    ref.current.setFieldValue("firstname", lastContract["firstname"]);
    ref.current.setFieldValue("user_status", lastContract["user_status"]);
    ref.current.setFieldValue("address", lastContract["address"]);
    ref.current.setFieldValue("postal_code", lastContract["postal_code"]);
    ref.current.setFieldValue("city", lastContract["city"]);
    ref.current.setFieldValue("email", lastContract["email"]);
    ref.current.setFieldValue("phone_number", lastContract["phone_number"]);
    ref.current.setFieldValue("siren", lastContract["siren"]);
    ref.current.setFieldValue("box", lastContract["box"]);
    console.log(lastContract);
  }
      , [agent]);

  const navigate = useNavigate();
  const theme = useTheme();
  const [errorMessage, setErrorMessage] = useState(null);
  
  const scriptedRef = useScriptRef();
  const {saveContract, isSaveContractMutationLoading} = useMlmService();
  const { data: agentInfos, error: getAgentInfosError, isLoading: getAgentInfosIsLoading } = useGetAgentInfosQuery();

  const signatureCanvasRef = useRef(null);
  const clearSignature = () => {
    signatureCanvasRef.current.clear();
  };
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
}
  const getSignature = () => {
    const signatureImage = signatureCanvasRef.current.toDataURL();
    let file = dataURLtoFile(signatureImage, 'Signature.png');

    console.log('Signature Image:', file);
    return file;
  };
  return (
    <MainCard
      sx={{
        maxWidth: 400,
        '& > *': {
          flexGrow: 1,
          flexBasis: '50%'
        }
      }}
      content={false}
    >
      <Box sx={{ p: 3 }}>

        <h1 style={{lineHeight:"35px"}}>CONTRAT REVENDEUR/VDI Little Egérie</h1>
        <Formik
        innerRef={ref}
        initialValues={{
          lastname: "",
          firstname: '',
          address: '',
          postal_code: '',
          city: '',
          email: '',
          phone_number: '',
          siren: '',
    
          box: 1,
          user_status: 1,
    
    
          submit: null
        }}
        validationSchema={Yup.object().shape({
          lastname: Yup.string().max(255).required("Veuillez renseigner votre nom de famille."),
          firstname: Yup.string().max(255).required('Veuillez renseigner vos prénoms.'),
          address: Yup.string().max(255).required("Veuillez renseigner votre adresse."),
          postal_code: Yup.string().max(255).required("Veuillez renseigner votre code postal."),
          city: Yup.string().max(255).required("Veuillez renseigner votre ville."),

          email: Yup.string().max(255).required("Veuillez renseigner votre email."),
          phone_number: Yup.string().max(255).required("Veuillez renseigner votre numéro de téléphone."),
          siren: Yup.string().max(255).required("Veuillez renseigner votre N°SIREN."),

          user_status: Yup.number().required("Veuillez renseigner votre statut."),
          box: Yup.number().required("Veuillez renseigner la box que vous allez commander."),



        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            if(signatureCanvasRef.current.isEmpty()) throw new Error("Veuillez fournir votre signature électronique.");
            let signatureFile = getSignature();

            setErrorMessage(null);
            if (scriptedRef.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
            const datas = {
              "lastname": values["lastname"],
              "firstname": values["firstname"],
              "address": values["address"],
              "postal_code": values["postal_code"],
              "city": values["city"],

              "email": values["email"],
              "phone_number": values["phone_number"],
              "siren": values["siren"],
              "box": values["box"],
              "user_status": values["user_status"],
            }

            const formData = new FormData();
            formData.append("signature", signatureFile, signatureFile.name);

            formData.append("lastname", values["lastname"]);
            formData.append("firstname", values["firstname"]);
            formData.append("address", values["address"]);

            formData.append("postal_code", values["postal_code"]);
            formData.append("city", values["city"]);
            formData.append("email", values["email"]);
            formData.append("phone_number", values["phone_number"]);
            formData.append("siren", values["siren"]);
            formData.append("box", values["box"]);
            formData.append("user_status", values["user_status"]);

            const res = await saveContract(formData);

            console.log('Result:', res);
          } catch (err) {
            console.log(err);
            setErrorMessage(err.data ? err.data.message : err.message);
            if (scriptedRef.current) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form noValidate onSubmit={handleSubmit}>
            <FormControl fullWidth error={Boolean(touched.user_status && errors.user_status)} style={{marginBottom:10, marginTop:10}} >
              <InputLabel id="outlined-adornment-user_status-agent-registration"  style={{top:"-8px"}}>Votre statut</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="outlined-adornment-user_status-agent-registration"
                value={values.user_status}
                label="Votre statut"
                onChange={handleChange}
                name="user_status"
              >
                <MenuItem value={1}>Revendeur</MenuItem>
                <MenuItem value={2}>VDI</MenuItem>
              </Select>
            </FormControl>
            {touched.user_status && errors.user_status && (
                <FormHelperText error id="standard-weight-helper-text-user_status-agent-registration">
                  {errors.user_status}
                </FormHelperText>
              )}
            <FormControl fullWidth error={Boolean(touched.lastname && errors.lastname)} sx={{ ...theme.typography.customInput }}>
              <InputLabel htmlFor="outlined-adornment-lastname-agent-registration">Nom de famille</InputLabel>
              <OutlinedInput
                id="outlined-adornment-lastname-agent-registration"
                type="text"
                value={values.lastname}
                name="lastname"
                onBlur={handleBlur}
                onChange={handleChange}
                label="Nom de famille"
                inputProps={{}}
              />
              {touched.lastname && errors.lastname && (
                <FormHelperText error id="standard-weight-helper-text-lastname-agent-registration">
                  {errors.lastname}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl fullWidth error={Boolean(touched.firstname && errors.firstname)} sx={{ ...theme.typography.customInput }}>
              <InputLabel htmlFor="outlined-adornment-firstname-agent-registration">Prénoms</InputLabel>
              <OutlinedInput
                id="outlined-adornment-firstname-agent-registration"
                type="text"
                value={values.firstname}
                name="firstname"
                onBlur={handleBlur}
                onChange={handleChange}
                label="Prénoms"
                inputProps={{}}
              />
              {touched.firstname && errors.firstname && (
                <FormHelperText error id="standard-weight-helper-text-firstname-agent-registration">
                  {errors.firstname}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl fullWidth error={Boolean(touched.address && errors.address)} sx={{ ...theme.typography.customInput }}>
              <InputLabel htmlFor="outlined-adornment-address-agent-registration">Adresse</InputLabel>
              <OutlinedInput
                id="outlined-adornment-address-agent-registration"
                type="text"
                value={values.address}
                name="address"
                onBlur={handleBlur}
                onChange={handleChange}
                label="Adresse"
                inputProps={{}}
              />
              {touched.address && errors.address && (
                <FormHelperText error id="standard-weight-helper-text-address-agent-registration">
                  {errors.address}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl fullWidth error={Boolean(touched.postal_code && errors.postal_code)} sx={{ ...theme.typography.customInput }}>
              <InputLabel htmlFor="outlined-adornment-postal_code-agent-registration">Code postal</InputLabel>
              <OutlinedInput
                id="outlined-adornment-postal_code-agent-registration"
                type="text"
                value={values.postal_code}
                name="postal_code"
                onBlur={handleBlur}
                onChange={handleChange}
                label="Code postal"
                inputProps={{}}
              />
              {touched.postal_code && errors.postal_code && (
                <FormHelperText error id="standard-weight-helper-text-postal_code-agent-registration">
                  {errors.postal_code}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl fullWidth error={Boolean(touched.city && errors.city)} sx={{ ...theme.typography.customInput }}>
              <InputLabel htmlFor="outlined-adornment-city-agent-registration">Ville</InputLabel>
              <OutlinedInput
                id="outlined-adornment-city-agent-registration"
                type="text"
                value={values.city}
                name="city"
                onBlur={handleBlur}
                onChange={handleChange}
                label="Ville"
                inputProps={{}}
              />
              {touched.city && errors.city && (
                <FormHelperText error id="standard-weight-helper-text-city-agent-registration">
                  {errors.city}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl fullWidth error={Boolean(touched.email && errors.email)} sx={{ ...theme.typography.customInput }}>
              <InputLabel htmlFor="outlined-adornment-email-agent-registration">Email</InputLabel>
              <OutlinedInput
                id="outlined-adornment-email-agent-registration"
                type="text"
                value={values.email}
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                label="Email"
                inputProps={{}}
              />
              {touched.email && errors.email && (
                <FormHelperText error id="standard-weight-helper-text-email-agent-registration">
                  {errors.email}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl fullWidth error={Boolean(touched.phone_number && errors.phone_number)} sx={{ ...theme.typography.customInput }}>
              <InputLabel htmlFor="outlined-adornment-phone_number-agent-registration">Numéro de téléphone</InputLabel>
              <OutlinedInput
                id="outlined-adornment-phone_number-agent-registration"
                type="text"
                value={values.phone_number}
                name="phone_number"
                onBlur={handleBlur}
                onChange={handleChange}
                label="Numéro de téléphone"
                inputProps={{}}
              />
              {touched.phone_number && errors.phone_number && (
                <FormHelperText error id="standard-weight-helper-text-phone_number-agent-registration">
                  {errors.phone_number}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl fullWidth error={Boolean(touched.siren && errors.siren)} sx={{ ...theme.typography.customInput }}>
              <InputLabel htmlFor="outlined-adornment-siren-agent-registration">N°SIREN</InputLabel>
              <OutlinedInput
                id="outlined-adornment-siren-agent-registration"
                type="text"
                value={values.siren}
                name="siren"
                onBlur={handleBlur}
                onChange={handleChange}
                label="N°SIREN"
                inputProps={{}}
              />
              {touched.siren && errors.siren && (
                <FormHelperText error id="standard-weight-helper-text-siren-agent-registration">
                  {errors.siren}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl fullWidth error={Boolean(touched.box && errors.box)} style={{marginTop:20, }}>
              <InputLabel id="outlined-adornment-box_to_purchase-agent-registration" style={{top:"-8px"}}>Box à commander</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="outlined-adornment-box_to_purchase-agent-registration"
                value={values.box}
                label="Box à commander"
                onChange={handleChange}
                name="box"
              >
                <MenuItem value={1}>Box Eclat à 99€</MenuItem>
                <MenuItem value={2}>Box Brillance à 149€</MenuItem>
                <MenuItem value={3}>Box Signature à 299€</MenuItem>
                <MenuItem value={4}>Box Prestige à 699€</MenuItem>

              </Select>
            </FormControl>
            {touched.box && errors.box && (
                <FormHelperText error id="standard-weight-helper-text-box-agent-registration">
                  {errors.box}
                </FormHelperText>
              )}
            <p><strong>Votre signature éléctronique</strong></p>
            <div style={{border:"solid #AAAAAA 1px",borderRadius: "10px", overflow:"hidden", background:"white"}}>
            <ReactSignatureCanvas  ref={signatureCanvasRef} penColor='black'
                canvasProps={{width: 300, height: 200, className: 'signatureCanvasRef'}} />
            </div>
            <Button disableElevation type="submit" variant="outlined" color="secondary" style={{textTransform:'none', marginTop:5, padding: 10, height:30}} onClick={clearSignature}>
                  Réinitialiser la signature
                </Button>


            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}

            <Box sx={{ mt: 2 }}>
              <AnimateButton>
                <LoadingButton disableElevation loading={isSaveContractMutationLoading} fullWidth size="large" type="submit" variant="contained" color="secondary" style={{textTransform:'none'}}>
                  Soumettre
                </LoadingButton>
              </AnimateButton>
            </Box>
          </form>
        )}
      </Formik>
      { errorMessage && <Alert severity="error" style={{marginTop:10}}>{errorMessage}</Alert>}

     </Box>
    </MainCard >
  );
}
export default AgentContractForm;
