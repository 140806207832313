// assets
import { IconDashboard, IconWallet, IconList, IconFileInvoice } from '@tabler/icons-react';

// constant
const icons = { IconDashboard, IconWallet, IconList,IconFileInvoice };


// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const me = {
  id: 'me',
  title: 'Mon compte',
  caption: 'Gérez votre compte',
  type: 'group',
  roles: ['ROLE_AGENT'],
  children: [
    {
      id: 'accounts',
      title: 'Mes portefeuilles',
      type: 'item',
      url: '/agent/me/accounts',
      icon: icons.IconWallet, 
      breadcrumbs: false
    },
    {
      id: 'invoices',
      title: 'Mes factures',
      type: 'item',
      url: '/agent/me/invoices',
      icon: icons.IconFileInvoice, 
      breadcrumbs: false
    },
    {
      id: 'operations',
      title: 'Mes opérations',
      type: 'item',
      url: '/agent/me/operations',
      icon: icons.IconList, 
      breadcrumbs: false
    },
  ]
};

export default me;
