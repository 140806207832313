// material-ui
import moment from 'moment';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Alert, Button, Chip, Link, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useFindAgentQuery } from 'apis/admin.api';
import { useGetAgentDetailsQuery } from 'apis/mlm.api';

// ==============================|| SAMPLE PAGE ||============================== //

const AgentDetailsPage = () => {
  const navigate = useNavigate();
  const { data: agent } = useGetAgentDetailsQuery();
  useEffect(() => {
    if (agent == null) return;
    console.log(agent);
  }, [agent]);

  const agentStatus = {
    "0": "Créé",
    "1": "Actif",
 "2": "En attente de validation",
 "-1": "Inactif",
  }
  const documentStatus = {
    "-1": "Invalide",
    "1": "Valide",
    "0": "En attente de validation",


  }
  const documentTypes = {
    "1": "Identité",
    "2": "KBis",
    "3": "Carte Vitale",
    "4": "SIREN VDI",

  }
  


  return (
    <MainCard title={`A propos de moi`}>
      {agent &&
        <>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableBody>
                <TableRow key="agentId" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Mon identifiant</TableCell>
                  <TableCell align="left"><b>{agent.id}</b></TableCell>
                </TableRow>
                <TableRow key="registrationNumber" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Numéro d'inscription</TableCell>
                  <TableCell align="left"><b>{agent.registrationNumber}</b></TableCell>
                </TableRow>
                <TableRow key="registrationDate" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Date de l'inscription</TableCell>
                  <TableCell align="left"><b>{agent?.registrationDate ? moment(agent.registrationDate, 'YYYY-MM-DD HH:mm:ss.SSSSSS').format('DD-MM-YYYY-MM à HH:mm') : "-"}</b></TableCell>
                </TableRow>
                <TableRow key="sponsor" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Parrain</TableCell>
                  <TableCell align="left"><b>{agent.sponsor ? agent.sponsor.username : '-'}</b></TableCell>
                </TableRow>
                <TableRow key="username" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Nom d'utilisateur</TableCell>
                  <TableCell align="left"><b>{agent.username ? agent.username : '-'}</b></TableCell>
                </TableRow>
                <TableRow key="status" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Statut</TableCell>
                  <TableCell align="left"><Chip label={agentStatus[agent.status]} color="primary" /></TableCell>
                </TableRow>


              </TableBody>
            </Table>
          </TableContainer>

          <br />
          <h3>Liste des pièces justificatives :</h3>
          {(agent.supportingDocuments.length == 0) && <Alert severity="info" style={{ marginTop: 10 }}>Aucune pièce justificative mentionnée.</Alert>}
          {(agent.supportingDocuments.length != 0) && 
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell >Nom du fichier</TableCell>
                  <TableCell>Type du document</TableCell>
                  <TableCell>Statut</TableCell>

                  <TableCell></TableCell>


                </TableRow>
              </TableHead>
              <TableBody>
                {agent.supportingDocuments.map((document, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell >{document.filename}</TableCell>
                    <TableCell >{documentTypes[document.documentType]}</TableCell>

                    <TableCell >{documentStatus[document.status]}</TableCell>

                    {
                      <TableCell style={{ maxWidth: 275 }}>
                        <AnimateButton>
                          <Button variant="contained"  disabled={document.filepath == null} color="primary" sx={{ boxShadow: 'none' }} style={{ textTransform: 'none' }}
                            onClick={async () => {
                              window.open(`${process.env.REACT_APP_SERVER_URL}/${document.filepath}`, "_blank")
                            }}>
                            Consulter le document
                          </Button>
                        </AnimateButton>
                      </TableCell>
                    }

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          }
          <br />
          <h3>Liste des contrats :</h3>
          {(agent.agentContracts.length == 0) && <Alert severity="info" style={{ marginTop: 10 }}>Aucun contrat signé.</Alert>}
          {(agent.agentContracts.length != 0) && 
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell >Date du contrat</TableCell>
                  <TableCell>Nom et prénoms</TableCell>
                  <TableCell>Adresse</TableCell>
                  <TableCell>Code postal</TableCell>
                  <TableCell>Ville</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Téléphone</TableCell>
                  <TableCell>N°SIREN</TableCell>



                  <TableCell></TableCell>


                </TableRow>
              </TableHead>
              <TableBody>
                {agent.agentContracts.map((document, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell >{document?.createdAt ? moment(document.createdAt, 'YYYY-MM-DD HH:mm:ss.SSSSSS').format('DD-MM-YYYY-MM à HH:mm') : "-"}</TableCell>
                    <TableCell >{document.lastname}</TableCell>
                    <TableCell >{document.address}</TableCell>
                    <TableCell >{document.postal_code}</TableCell>
                    <TableCell >{document.city}</TableCell>
                    <TableCell >{document.email}</TableCell>
                    <TableCell >{document.phone_number}</TableCell>
                    <TableCell >{document.siren}</TableCell>


                    {
                      <TableCell style={{ maxWidth: 275 }}>
                        <AnimateButton>
                          <Button variant="contained" disabled={document.filepath == null} color="primary" sx={{ boxShadow: 'none' }} style={{ textTransform: 'none' }}
                            onClick={async () => {
                              window.open(`${process.env.REACT_APP_SERVER_URL}/${document.filepath}`, "_blank")
                            }}>
                            Voir le contrat
                          </Button>
                        </AnimateButton>
                      </TableCell>
                    }

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          }
        </>
      }




    </MainCard>
  );
}

export default AgentDetailsPage;
