
// project imports
import { Link, useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
// project imports
import Logo from 'ui-component/Logo';
import AuthFooter from 'ui-component/cards/AuthFooter';
import AuthCardWrapper from 'views/pages/authentication/AuthCardWrapper';
import MainCard from 'ui-component/cards/MainCard';
import { useEffect, useRef, useState } from 'react';
import { useGetAgentInfosQuery } from 'apis/mlm.api';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useStripeService } from 'services/stripe.service';
import { useSelector } from 'react-redux';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
  useMediaQuery
} from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';
import useScriptRef from 'hooks/useScriptRef';
import { useMlmService } from 'services/mlm.service';
import { LoadingButton } from '@mui/lab';
import ReactSignatureCanvas from 'react-signature-canvas';
import { borderColor, borderRadius } from '@mui/system';
import { useAdminService } from 'services/admin.service';
// ==============================|| SAMPLE PAGE ||============================== //

const AdminPromoCodeForm = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [errorMessage, setErrorMessage] = useState(null);
  
  const scriptedRef = useScriptRef();
  const {createPromoCode, isCreatePromoCodeLoading} = useAdminService();
  return (
    <MainCard
      sx={{
        maxWidth: 400,
        '& > *': {
          flexGrow: 1,
          flexBasis: '50%'
        }
      }}
      content={false}
    >
      <Box sx={{ p: 3 }}>

        <h1 style={{lineHeight:"35px"}}>Formulaire de création de code promo</h1>
        <Formik
        initialValues={{
          code: '',
          startDate: '',
          endDate: '',
          submit: null
        }}
        validationSchema={Yup.object().shape({
          code: Yup.string().max(255).required("Veuillez renseigner le code promo."),
          startDate: Yup.date().required('Veuillez renseigner la date de début de la promotion.'),
          endDate: Yup.date().required("Veuillez renseigner la date de fin de la promotion."),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            setErrorMessage(null);
            if (scriptedRef.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
            const datas = {
              "code": values["code"],
              "startDate": values["startDate"],
              "endDate": values["endDate"]
            }
            const res = await createPromoCode(datas);

            console.log('Result:', res);
            navigate('/admin/promo_codes');
          } catch (err) {
            console.log(err);
            setErrorMessage(err.data ? err.data.message : err.message);
            if (scriptedRef.current) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form noValidate onSubmit={handleSubmit}>
            
            <FormControl fullWidth error={Boolean(touched.code && errors.code)} sx={{ ...theme.typography.customInput }}>
              <InputLabel htmlFor="outlined-adornment-code-agent-registration">Code de promotion</InputLabel>
              <OutlinedInput
                id="outlined-adornment-code-agent-registration"
                type="text"
                value={values.code}
                name="code"
                onBlur={handleBlur}
                onChange={handleChange}
                label="Code de promotion"
                inputProps={{}}
              />
              {touched.code && errors.code && (
                <FormHelperText error id="standard-weight-helper-text-code-agent-registration">
                  {errors.code}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl fullWidth error={Boolean(touched.startDate && errors.startDate)} sx={{ ...theme.typography.customInput }}>
              <InputLabel htmlFor="outlined-adornment-startDate-agent-registration">Date de début de la promotion</InputLabel>
              <OutlinedInput
                id="outlined-adornment-startDate-agent-registration"
                type="text"
                value={values.startDate}
                name="startDate"
                onBlur={handleBlur}
                onChange={handleChange}
                label="Date de début de la promotion"
                inputProps={{}}
                type="date"
              />
              {touched.startDate && errors.startDate && (
                <FormHelperText error id="standard-weight-helper-text-startDate-agent-registration">
                  {errors.startDate}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl fullWidth error={Boolean(touched.endDate && errors.endDate)} sx={{ ...theme.typography.customInput }}>
              <InputLabel htmlFor="outlined-adornment-endDate-agent-registration">Date de fin de la promotion</InputLabel>
              <OutlinedInput
                id="outlined-adornment-endDate-agent-registration"
                type="text"
                value={values.endDate}
                name="endDate"
                onBlur={handleBlur}
                onChange={handleChange}
                label="Date de fin de la promotion"
                inputProps={{}}
                type="date"
              />
              {touched.endDate && errors.endDate && (
                <FormHelperText error id="standard-weight-helper-text-endDate-agent-registration">
                  {errors.endDate}
                </FormHelperText>
              )}
            </FormControl>
            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}

            <Box sx={{ mt: 2 }}>
              <AnimateButton>
                <LoadingButton disableElevation loading={isCreatePromoCodeLoading} fullWidth size="large" type="submit" variant="contained" color="secondary" style={{textTransform:'none'}}>
                  Créer
                </LoadingButton>
              </AnimateButton>
            </Box>
          </form>
        )}
      </Formik>
      { errorMessage && <Alert severity="error" style={{marginTop:10}}>{errorMessage}</Alert>}

     </Box>
    </MainCard >
  );
}
export default AdminPromoCodeForm;
